import React from 'react';
import { HydraAdmin } from '@api-platform/admin';
import parseHydraDocumentation from '@api-platform/api-doc-parser/lib/hydra/parseHydraDocumentation';
import {
    dataProvider as baseDataProvider,
    fetchHydra as baseFetchHydra,
    ResourceGuesser  ,
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
} from '@api-platform/admin';
import authProvider from './authProvider';
import { Redirect } from 'react-router-dom';

import { Admin, Resource } from 'react-admin';

import Dashboard from './Dashboard';

import {UsersList, UsersCreate, UserEdit } from './Users';
import {PropertiesCreate, PropertiesList, PropertiesEdit} from "./Properties";
import {RentalContractsCreate, RentalContractsList, RentalContractsEdit} from "./RentalContracts";
import {BookingsCreate, BookingsEdit, BookingsList} from "./Bookings";
import {ExpensesCreate, ExpensesEdit, ExpensesList} from "./Expenses";
import {CustomersCreate, CustomersEdit, CustomersList} from "./Customers";
import {MediaObjectsCreate, MediaObjectsList} from "./MediaObjects";
import {VarietiesCreate, VarietiesEdit, VarietiesList} from "./Varieties";
import {CommercesCreate, CommercesEdit, CommercesList} from "./Commerces";
import {OrangeSalesCreate, OrangeSalesEdit, OrangeSalesList} from "./OrangeSales";
import {VehicleTasksCreate, VehicleTasksEdit, VehicleTasksList} from "./VehicleTasks";
import {VehiclesCreate, VehiclesEdit, VehiclesList} from "./Vehicle";
import {WorkshopCreate, WorkshopEdit, WorkshopList} from "./Workshop";
import {OrangePricesList, OrangePricesEdit, OrangePricesCreate} from "./OrangePrices";
import {HomeTaskEdit, HomeTaskCreate, HomeTasksList} from "./HomeTasks";
import {HomeTaskHistoryCreate, HomeTaskHistoryEdit, HomeTaskHistoryList} from "./HomeTaskHistories";
import {IncidenceEdit, IncidencesList, IncidenceCreate} from "./Incidences";

const entrypoint = process.env.REACT_APP_API_ENTRYPOINT;
const fetchHeaders = {'Authorization': `Bearer ${window.localStorage.getItem('token')}`};
const fetchHydra = (url, options = {}) => baseFetchHydra(url, {
    ...options,
    headers: new Headers(fetchHeaders),
});
const apiDocumentationParser = entrypoint => parseHydraDocumentation(entrypoint, { headers: new Headers(fetchHeaders) })
    .then(
        ({ api }) => ({api}),
        (result) => {
            switch (result.status) {
                case 401:
                    return Promise.resolve({
                        api: result.api,
                        customRoutes: [{
                            props: {
                                path: '/',
                                render: () => <Redirect to={`/login`}/>,
                            },
                        }],
                    });

                default:
                    return Promise.reject(result);
            }
        },
    );
const dataProvider = baseDataProvider(entrypoint, fetchHydra, apiDocumentationParser);


export default props => (
    <HydraAdmin
        apiDocumentationParser={ apiDocumentationParser }
        dataProvider={ dataProvider }
        authProvider={ authProvider }
        entrypoint={ entrypoint }
        dashboard={Dashboard}
    >
        <ResourceGuesser name="users" create={UsersCreate} list={UsersList} edit={UserEdit}/>
        <ResourceGuesser name="properties" create={PropertiesCreate} list={PropertiesList} edit={PropertiesEdit}/>
        <ResourceGuesser name="incidences" create={IncidenceCreate} list={IncidencesList} edit={IncidenceEdit}/>
        <ResourceGuesser name="expenses" create={ExpensesCreate} list={ExpensesList} edit={ExpensesEdit}/>
        <ResourceGuesser name="customers" create={CustomersCreate} list={CustomersList} edit={CustomersEdit}/>
        <ResourceGuesser name="rental_contracts" create={RentalContractsCreate} list={RentalContractsList} edit={RentalContractsEdit}/>
        <ResourceGuesser name="bookings" create={BookingsCreate} list={BookingsList} edit={BookingsEdit}/>
        <ResourceGuesser name="varieties" create={VarietiesCreate} list={VarietiesList} edit={VarietiesEdit}/>
        <ResourceGuesser name="commerces" create={CommercesCreate} list={CommercesList} edit={CommercesEdit}/>
        <ResourceGuesser name="orange_sales" create={OrangeSalesCreate} list={OrangeSalesList} edit={OrangeSalesEdit}/>
        <ResourceGuesser name="vehicles" create={VehiclesCreate} list={VehiclesList} edit={VehiclesEdit}/>
        <ResourceGuesser name="vehicle_tasks" create={VehicleTasksCreate} list={VehicleTasksList} edit={VehicleTasksEdit}/>
        <ResourceGuesser name="workshops" create={WorkshopCreate} list={WorkshopList} edit={WorkshopEdit}/>
        <ResourceGuesser name="products"/>
        <ResourceGuesser name="home_tasks" create={HomeTaskCreate} list={HomeTasksList} edit={HomeTaskEdit} />
        <ResourceGuesser name="home_task_histories" create={HomeTaskHistoryCreate} list={HomeTaskHistoryList} edit={HomeTaskHistoryEdit} />
        <ResourceGuesser name="orange_prices" list={OrangePricesList}/>
        <ResourceGuesser name="media_objects" create={MediaObjectsCreate} list={MediaObjectsList}/>

    </HydraAdmin>

);
